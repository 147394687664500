import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';

const { generalBorderRadius } = VARIABLES;

export const css = {
  checkbox: {
    borderRadius: generalBorderRadius,
    fontSize: '1rem',
    padding: '0',
    height: 'auto',
    lineHeight: 1,
    display: 'inline-flex',
    alignItems: 'flex-start',
    '& .ant-checkbox': {
      top: 1,
    },
  },
  label: {
    fontSize: '.875rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    checkbox: {},
  },
};

export default css;
