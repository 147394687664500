import activeRu from './translations/ru/active.json';
import documentsRu from './translations/ru/documents.json';
import printFormsRu from './translations/ru/printForms.json';
import widgetsRu from './translations/ru/widgets.json';
import headerRu from './translations/ru/header.json';
import contractRu from './translations/ru/contract.json';
import signOfferRu from './translations/ru/signOffer.json';

export const translations = {
  ru: {
    active: activeRu,
    documents: documentsRu,
    printForms: printFormsRu,
    widgets: widgetsRu,
    header: headerRu,
    contract: contractRu,
    signOffer: signOfferRu,
  },
};
