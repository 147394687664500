import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { footerBackground, greyLightText, white } from '../../../colors';
import { generalTransition } from '../../../variables';

import { css as original } from '@custom/defaults/theme/Components/ActiveCreditPage/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  creditInfo: {
    display: 'none',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
});

export default css;
