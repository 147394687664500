export enum InterfaceModules {
  REGISTRATION = 'REGISTRATION',
  MESSAGE_WIDGET = 'MESSAGE_WIDGET',
  DISCLAIMER_WIDGET = 'DISCLAIMER_WIDGET',
  PROLONGATION_WIDGET = 'PROLONGATION_WIDGET',
  CREATE_LOANAPP_WIDGET = 'CREATE_LOANAPP_WIDGET',
  EARLY_REPAYMENT_WIDGET = 'EARLY_REPAYMENT_WIDGET',
  REGULAR_REPAYMENT_WIDGET = 'REGULAR_REPAYMENT_WIDGET',
  INTEREST_REPAYMENT_WIDGET = 'INTEREST_REPAYMENT_WIDGET',
  ARBITRARY_REPAYMENT_WIDGET = 'ARBITRARY_REPAYMENT_WIDGET',
  KYC_LICENSE_AGREEMENT = 'KYC_LICENSE_AGREEMENT',
  AUTH_PAGE_DISCLAIMER = 'AUTH_PAGE_DISCLAIMER',
  FOOTER = 'FOOTER',
  CONTRACT_SCHEDULE = 'CONTRACT_SCHEDULE',
  SERVICES_BUTTON = 'SERVICES_BUTTON',
  MAILING_SETTINGS = 'MAILING_SETTINGS',
  FULL_COST_STEP = 'FULL_COST_STEP',
  CONTRACT_INFO_ANNOTATIONS = 'CONTRACT_INFO_ANNOTATIONS',
  DOCUMENTS_PAGE = 'DOCUMENTS_PAGE',
  LOAN_APPS_PAGE = 'LOAN_APPS_PAGE',
  CONTRACTS_PAGE = 'CONTRACTS_PAGE',
  PAYMENTS_PAGE = 'PAYMENTS_PAGE',
  COMPLAIN_PAGE = 'COMPLAIN_PAGE',
}
