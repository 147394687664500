import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';

const { greyLightText, greyText, primary, white, black } = THEME_COLORS;
const { generalBorder, generalBorderRadius, topBarHeight } = VARIABLES;

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  statefulProcessPageWrap: {
    background: white,
    borderRadius: generalBorderRadius,
    width: '100%',
    flex: 1,
    padding: '1.5rem',
    fontSize: '.9125rem',
  },
  statefulProcessHeader: {
    textAlign: 'center',
    fontSize: '1.125rem',
    marginTop: 0,
    lineHeight: 1.2,
  },
  statefulProcessActionsWrapper: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    '& .ant-btn:not(:last-child)': {
      marginRight: '.75rem',
    },
  },
  skeleton: {
    '& .ant-skeleton-content .ant-skeleton-paragraph > li': {
      height: '1.25rem',
      marginBottom: '1.5rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
