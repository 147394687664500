export enum SettingsType {
  PRINT_FORMS = 'PRINT_FORMS',
  GETTING_MONEY_METHOD = 'GETTING_MONEY_METHOD',
  INTERFACE_MODULES = 'INTERFACE_MODULES',
  UNEMPLOYED = 'UNEMPLOYED',
  REPAYMENT_WITH_DEFAULT_CARD = 'REPAYMENT_WITH_DEFAULT_CARD',
  PROLONGATION_PROCESS_ID = 'PROLONGATION_PROCESS_ID',
  PROLONGATION_POSTPROCESS_ID = 'PROLONGATION_POSTPROCESS_ID',
  FILE_DOWNLOAD_PROXY = 'FILE_DOWNLOAD_PROXY',
  MARKETING_ACTIONS = 'MARKETING_ACTIONS',
  COMPLEMENTARY_DOC_TYPE = 'COMPLEMENTARY_DOC_TYPE',
  SELFIE_REQUEST_AMOUNT_THRESHOLD = 'SELFIE_REQUEST_AMOUNT_THRESHOLD',
  SIGN_OFFER_LIMIT = 'SIGN_OFFER_LIMIT',
  WRAP_PAYMENTS_IN_IFRAME = 'WRAP_PAYMENTS_IN_IFRAME',
  WRAP_CARD_BINDING_IN_IFRAME = 'WRAP_CARD_BINDING_IN_IFRAME',
  OFFERTA_INSURANCE_ACCEPTED_DEFAULT = 'OFFERTA_INSURANCE_ACCEPTED_DEFAULT',
  OFFERTA_INSURANCE_IS_CHECKBOX = 'OFFERTA_INSURANCE_IS_CHECKBOX',
  SHOW_DOCUMENTS_DATE = 'SHOW_DOCUMENTS_DATE',
  RISK_PDN_NOTIFICATION_PRESET = 'PDN_NOTIFICATION_PRESET',
}
