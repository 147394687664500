import { TENANT_DOMAIN } from '@brainysoft/lk-custom/config';
import { displayError } from './displayError';
import Storage, { Store } from './cache';
import { FetchWithTokenT } from './fetchWithToken';
import { tokenService } from './token';
import { parseCookies } from 'nookies';

class API {
  public fetch: any;
  public urlAPI: Record<string, string>;

  constructor(fetch: FetchWithTokenT, urlAPI: Record<string, string>, storage: Store) {
    this.urlAPI = urlAPI;
    this.fetch = async (
      url: string,
      headers: Record<string, string>,
      middleware: (response: Response) => Promise<any>,
      ctx?: any
    ) => {
      return fetch(url, headers, ctx)
        .then(async (response) => {
          // console.log('before middleware response', response);
          return API.errorMiddleware(response, url, headers);
        })
        .then(async (response: Response) => {
          return middleware(response);
        })
        .catch((error: Error) => {
          if (error?.message !== 'API_ERROR_MIDDLEWARE') {
            console.error('API:', error);
          }
        });
    };
  }

  public getUrlAPI(key: string, queryURI = '') {
    return (this.urlAPI[key] ? this.urlAPI[key] : '/') + (queryURI.toString() ? `?${queryURI}` : '');
  }

  public async get(
    entity: string,
    query: Record<string, unknown>,
    subLink: string,
    ctx: any = null,
    options: Record<string, unknown> = {}
  ) {
    const queryURI = API.makeURIParams(query);
    return this.fetch(
      this.getUrlAPI(entity, queryURI) + subLink,
      {
        method: 'GET',
        headers: API.buildHeaders({ 'x-process-uuid': options.processUuid }),
      },
      API.dataMiddleware,
      ctx
    );
  }

  public async post(
    entity: string,
    data: any,
    subLink: string,
    multipart = false,
    options: Record<string, unknown> = {}
  ) {
    const cookies = parseCookies();
    const cookiesString = `adspire_uid: ${cookies['adspire_uid']}; atm_marketing: ${cookies['atm_marketing']}; atm_remarketing: ${cookies['atm_remarketing']}; atm_closer: ${cookies['atm_closer']}`;

    return this.fetch(
      this.getUrlAPI(entity) + subLink,
      {
        method: 'POST',
        headers: API.buildHeaders(
          {
            'x-process-uuid': options.processUuid,
          },
          multipart
        ),
        body: API.buildBody(data, multipart),
      },
      API.dataMiddleware
    );
  }

  public async download(entity: string, query: Record<string, unknown>, subLink: string) {
    const queryURI = API.makeURIParams(query);
    return this.fetch(
      this.getUrlAPI(entity, queryURI) + subLink,
      { headers: API.buildHeaders({}) },
      async (response: Response) => {
        return response.blob();
      }
    );
  }

  private static makeURIParams(query: Record<string, unknown>) {
    const data = Object.entries(query);
    return data
      .reduce((acc, el) => {
        acc.push(el.join('='));
        return acc;
      }, <string[]>[])
      .join('&');
  }

  private static async errorMiddleware(
    response: Response,
    url?: string,
    headers?: Record<string, string>
  ): Promise<Response> {
    let error;
    if (response.status >= 400) {
      const data = await response.text();

      try {
        error = JSON.parse(data);
      } catch (e) {
        throw new Error(String(response.status));
      }

      if (response.status === 401 && error?.message === 'ERROR__CONSUMER_IS_BLOCKED') {
        //пользователь заблокирован
        console.error('CONSUMER_IS_BLOCKED');
        await tokenService.logout();
        await Storage.clear();
        window.location.href = '/auth/blocked';
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        error?.message === 'NOT_AUTHENTICATED' ||
        error?.message === 'ERROR__CONSUMER_TOKEN_INVALID'
      ) {
        //пользователь не авторизован
        console.error('NOT_AUTHENTICATED');
        await tokenService.logout();
        await Storage.clear();
        window.location.href = '/auth';
      } else {
        displayError(error?.errors ?? error, url, headers);
      }
      throw new Error(`API_ERROR_MIDDLEWARE`);
    }

    return response;
  }

  private static async dataMiddleware(response: Response): Promise<Record<string, unknown> | null> {
    let JSONData;
    try {
      JSONData = await response.json();
    } catch (e) {
      throw new Error('JSON parse error');
    }

    if (!JSONData || !JSONData.data) {
      return null;
    }

    const { data } = JSONData;
    return data;
  }

  private static buildHeaders(data: Record<string, unknown> = {}, multipart = false) {
    const headers = {};
    Object.keys(data).forEach((headerName) => {
      if (data[headerName]) headers[headerName] = data[headerName];
    });

    return multipart
      ? {
          ...headers,
          'X-TENANT-DOMAIN': TENANT_DOMAIN,
        }
      : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...headers,
          'X-TENANT-DOMAIN': TENANT_DOMAIN,
        };
  }

  private static buildBody(data: any, multipart = false) {
    return multipart ? data : JSON.stringify(data);
  }
}

export default API;
