import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { footerBackground, greyLightText, white } from '../../../colors';
import { generalTransition } from '../../../variables';

import { css as original } from '@custom/defaults/theme/Components/Footer/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  footerWrapper: {
    background: footerBackground,
    boxShadow: '0 -2px 5px rgba(0,0,0,0.05)',
    display: 'flex',
    '& a': {
      lineHeight: 1.2,
      color: white,
      opacity: 0.8,
      transition: generalTransition,
      textDecoration: 'none',
      '&:hover': {
        opacity: 1,
      },
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
});

export default css;
