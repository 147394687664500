import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { merge } from 'lodash';
import { css as original } from '@custom/defaults/theme/Components/AuthPage/styles';

export const css = merge(original, {
  pageTitle: {
    fontSize: '1.5rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
});

export default css;
