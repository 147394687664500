import { logo } from '../../../variables';

import { css as original } from '@custom/defaults/theme/Components/Layout/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  leftSide: {
    width: '50%',
  },
  rightSide: {
    width: '50%',
  },
});

export default css;
