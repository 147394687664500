const SERVER_BACKEND_DOMAIN = process.env.SERVER_BACKEND_DOMAIN ?? '';

export const baseUrlApi = `${SERVER_BACKEND_DOMAIN}/api/v1`;

export default {
  urlAPI: {
    getLegalEntityInfo: `${baseUrlApi}/legal_entity/info`,
    signIn: `${baseUrlApi}/otp/auth/challenge_answer`,
    signOut: `${baseUrlApi}/logout`,
    isAuthenticated: `${baseUrlApi}/isAuthenticated`,

    client: `${baseUrlApi}/client`,
    otp: `${baseUrlApi}/otp`,
    lead: `${baseUrlApi}/lead`,
    leads: `${baseUrlApi}/leads`,
    contract: `${baseUrlApi}/contract`,
    fundTransactions: `${baseUrlApi}/fundTransactions`,
    process: `${baseUrlApi}/process`,
    dictionary: `${baseUrlApi}/dict`,
    payments: `${baseUrlApi}/payments`,
    kyc: `${baseUrlApi}/kyc`,
    settings: `${baseUrlApi}/settings`,
    entity: `${baseUrlApi}/entity`,
    printForm: `${baseUrlApi}/printForm`,
    counter: `${baseUrlApi}/counter`,
    documents: `${baseUrlApi}/documents`,
    creditProducts: `${baseUrlApi}/creditProducts`,
    credit: `${baseUrlApi}/credit`,
    subscriptions: `${baseUrlApi}/subscriptions`,
    file: `${baseUrlApi}/file`,
    statefulProcess: `${baseUrlApi}/statefulProcess`,
    buildInfo: `${baseUrlApi}/build-info`,
    creditCalc: `${baseUrlApi}/credit-calc`,
    externalAuth: `${baseUrlApi}/external-auth`,
    surveys: `${baseUrlApi}/surveys`,
    dataSources: `${baseUrlApi}/data-sources`,
    verification: `${baseUrlApi}/verification`,
    jivo: `${baseUrlApi}/jivo`,
    addons: `${baseUrlApi}/addons`,
    sbp: `${baseUrlApi}/sbp`,
    check: `${baseUrlApi}/check`,
  },
};

const noDataAutoload = ['/auth/external-auth-redirect'];

const unauthorizedAccess = [
  '/auth',
  '/auth/*',
  // '/auth/blocked',
  // '/auth/external-auth-result',
  // '/auth/external-auth-redirect',
  // '/auth/check-phone-result',
  '/registration',
  '/registration/*',
  // '/registration/external-auth-result',
  '/start',
  '/start/*',
  '/verification/*',
  '/get-build-info',
];

export const authorizedAccess: (path: string) => boolean = (path) => {
  return !unauthorizedAccess.filter((item) => {
    const regex = new RegExp(`^(${item.replace('*', '.*')})$`);
    return !!path.match(regex);
  }).length;
};

export const dataAutoload: (path: string) => boolean = (path) => {
  return !noDataAutoload.includes(path);
};
