const colors = require('./theme_colors');

module.exports = {
  logo: 'https://cdn.brainysoft.ru/lk/tenants/8d776b44-0b19-4f7e-bf2e-bebc557757db/FosFin.svg',
  logoInverted: 'https://cdn.brainysoft.ru/lk/tenants/2841c282-0e4f-c2aa-c28e-1ac39bc2b640/FosFin_inverted.svg',
  logoAuth: 'https://cdn.brainysoft.ru/lk/tenants/8d776b44-0b19-4f7e-bf2e-bebc557757db/FosFin.svg',
  logoRegistration: 'https://cdn.brainysoft.ru/lk/tenants/8d776b44-0b19-4f7e-bf2e-bebc557757db/FosFin.svg',
  logoFooter: 'https://cdn.brainysoft.ru/lk/tenants/8d776b44-0b19-4f7e-bf2e-bebc557757db/FosFin_inverted.svg',
  authBackground: 'https://cdn.brainysoft.ru/lk/tenants/8d776b44-0b19-4f7e-bf2e-bebc557757db/autumn.jpg',
  maxWidth: '1600px',
  fontFamily:
    '"Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  topBarHeight: '102px',
  topBarHeightMobile: '80px',
  generalBorder: `1px solid ${colors.greyLight}`,
  generalBorderLight: `1px solid ${colors.greyLighter}`,
  generalBorderRadius: '6px',
  generalBorderRadiusLarge: '9px',
  generalTransition: 'all 0.3s ease',
  generalTableBorderRadius: '0.5rem',
  loaderBarColor: colors.primary,
  fontSizeBase: '14px',
  fontSizeLarge: '16px',
  fontSizeSmall: '13px',
  inputBorderColor: colors.primary,
};
